/* eslint-disable max-len */
import React from 'react'
import { connect } from 'react-redux'
import {
  MenuItem, FormControl, Select, Drawer, IconButton, Checkbox, ListItemText, Theme, Divider, Button,
  TextField, Badge, FormHelperText,
} from '@mui/material'
import { createTheme, ThemeProvider, adaptV4Theme } from '@mui/material/styles'
import { withTheme } from '@mui/styles'
// import { useTheme } from '@emotion/react'
import FilterListIcon from '@mui/icons-material/FilterList'
import Autocomplete from '@mui/lab/Autocomplete'
import { compose } from 'redux'
import { ChildDataProps, graphql, withApollo } from '@apollo/react-hoc'
import { gql, ApolloClient } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { withRouter, RouteComponentProps } from 'react-router'
import { withFormik, InjectedFormikProps } from 'formik'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import _, { cloneDeep } from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  // isSameDay,
  endOfYear,
  format,
} from 'date-fns'
import { IMetricConfig } from 'graphql/graphql.d'
import { flattenMessages, formateMessage, getGMT, IQuestionnaireComponent } from 'containers/Insight/utils'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  IState, IResponse, IOuterProps,
} from './constants'
import { cx } from '../../emotion'
import { displayFilterValue } from './util'
import DateRangeSelector from './dateRangeSelector'

export interface QuestionFilterJSON {
  [key: string]: { [key: string]: string[] }
}

interface IInnerProps extends ChildDataProps<unknown, IResponse>, RouteComponentProps<{ campaignId: string }> {
  questionFilter: QuestionFilterJSON
  timeRangeStartDate: Date
  timeRangeEndDate: Date
  dimension: Array<{ name: string, values: string[] }>
  handleTimerangeSelection(startDate: Date, endDate: Date): void
  handleFilterChange(param: any): void
  handleOptionsChange(param: any): void
  changeFilterSettingsAfterApply(param: any): void
  clearFilterSettings(): void
  changeFilterVisibility(visibility: boolean): void
  questionFilterAllOptions: { [key: string]: { label: string, value: string }[] }
  theme: Theme
  isFilterDrawerVisible: boolean
  handleNPSScoreFilter(param: Array<string>): void
  handleResponseTypeFilter(param: Array<string>): void
  npsScoreFilter: Array<string>
  responseType: Array<string>
  getDimensionAndUniqueAnswers: any[]
  hasNetPromoterScoreQuestion: boolean
  metricConfig: IMetricConfig[]
  questionnaire: IQuestionnaireComponent[]
  handleSegmentFilter(segmentName: string, segmentValue: string[], allValues: string[], segmentLevels: { [key: string]: string | string[] }[]): void
  segmentFilter: any
  getCampaignEnabledStatusforPreCalculation: any
  userData: any
  client: ApolloClient<unknown>
  languageSelected: string
  locale: string
  getSegmentLevels: { segmentName: string, segmentValue: string[], selectedSegmentValues: string[] }[]
}

interface IValues {
  selectedDateTo: string
  selectedDateFrom: string
  selectedAllValues: string[]
  showValue: boolean
  isNewSegmentEnabled: boolean
  segmentLevels: { segmentName: string, segmentValue: string[], selectedSegmentValues: string[] }[]
}

const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'dark',
    background: {
      default: '#283141',
    },
    text: {
      primary: '#e8e9eb',
    },
  },
}))

const formatDateForCalendarValue = (startDate, endDate) => [format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')]

export const allSelectionValue = '--ALL--'

const predefinedRanges = [
  'This month', 'Last month', 'This week (starts Monday)',
  'Last week (starts Monday)', 'This Quarter', 'This Indian financial year',
  'Last 7 days', 'Last 14 days', 'Last 28 days', 'Last 30 days',
  'Last 60 days', 'Last 90 days', 'Today',
  'Yesterday', 'This week (starts Sunday)', 'Last week (starts Sunday)',
  'Last Quarter', 'This year', 'Last year',
]

const getAllValues = (arg: Array<{ label: string, shortCode: string, value: string }> | undefined) => {
  if (!arg) return []

  const allValues = arg.map((v) => v.value)
  if (allValues.includes('ALL')) {
    return ['ALL']
  }
  return allValues
}

const GET_SEGMENT_VALUE = gql`query($segmentName: String!, $segmentValue: [String!]!, $campaignId: ID!, $getSelectionForSegment: String!) {
  segmentValues(segmentName: $segmentName, segmentValue: $segmentValue, campaignId: $campaignId, getSelectionForSegment: $getSelectionForSegment)
}`

class Filter extends React.Component<InjectedFormikProps<IOuterProps & IInnerProps, IValues>, IState> {
  usableSegment = ['Circle', 'RM', 'Zonal Manager', 'Service Manager', 'Sales Manager', 'ASM', 'Service Engineer', 'Dealer Code', 'Dealer Name', 'Branch Code', 'Branch Name', 'Region', 'Hub', 'City', 'State']

  constructor(props) {
    super(props)
    this.state = {
      isDateSelectorVisible: false,
      anchorEl: null,
      selectedQuestionFilters: this.props.questionFilter,
      selectedNPSFilters: this.props.npsScoreFilter,
      selectedResponseFilters: this.props.responseType,
      isApplyClicked: false,
      isDialogBoxOpen: false,
      selectedOption: [],
      isSegmentClicked: false,
      segmentAnchorEl: null,
      isLastSegment: false,
      selectedListItemIndex: -1,
    }
  }

  handleTimerangeSelection = (dateRange: [Date, Date]) => {
    this.props.handleTimerangeSelection(startOfDay(dateRange[0] as Date), endOfDay(dateRange[1] as Date))
  }

  discardFilters = () => {
    this.props.changeFilterVisibility(false)
    this.setState({
      isDialogBoxOpen: false,
      selectedQuestionFilters: this.props.questionFilter,
      selectedNPSFilters: this.props.npsScoreFilter,
      selectedResponseFilters: this.props.responseType,
      isApplyClicked: false,
    })
  }

  applyFilters = () => {
    this.props.handleNPSScoreFilter(this.state.selectedNPSFilters as Array<string>)
    this.props.handleResponseTypeFilter(this.state.selectedResponseFilters as Array<string>)
    this.props.changeFilterSettingsAfterApply(this.state.selectedQuestionFilters)
    this.setState({ isApplyClicked: false })
    this.props.changeFilterVisibility(false)
    this.setState({ isDialogBoxOpen: false })
  }

  applySegment = () => {
    this.props.changeFilterSettingsAfterApply({})
    let getSegment
    const lastIdx = this.props.values.segmentLevels.length - 1
    const selectedFilter = this.props.getDimensionAndUniqueAnswers.find((val) => val.attributeName === this.props.values.segmentLevels[lastIdx].segmentName)
    if (this.props.values.segmentLevels.length < 2) {
      if (!selectedFilter.options.find((idx: any) => idx.label === 'ALL') && this.props.values.segmentLevels[lastIdx].selectedSegmentValues.includes('ALL')) {
        const segVal = selectedFilter.options.map((val) => val.label)
        const newObj = {
          segmentName: this.props.values.segmentLevels[lastIdx].segmentName,
          segmentValue: segVal,
          selectedSegmentValues: segVal,
        }
        getSegment = newObj
      } else {
        getSegment = this.props.values.segmentLevels[lastIdx]
      }
    } else if (this.props.values.segmentLevels[lastIdx].selectedSegmentValues.includes('ALL')) {
      const segVal = this.props.values.segmentLevels[lastIdx].segmentValue.filter((idx) => idx !== 'ALL')
      const newObj = {
        segmentName: this.props.values.segmentLevels[lastIdx].segmentName,
        segmentValue: segVal,
        selectedSegmentValues: segVal,
      }
      getSegment = newObj
    } else {
      getSegment = this.props.values.segmentLevels[lastIdx]
    }
    if (getSegment.segmentName === 'NPS') {
      let npsFilterOptions
      if (getSegment.selectedSegmentValues.includes('ALL')) {
        npsFilterOptions = ['PROMOTER', 'PASSIVE', 'DETRACTOR', '--ALL--']
        this.props.handleNPSScoreFilter(npsFilterOptions)
      } else {
        this.props.handleNPSScoreFilter(getSegment.selectedSegmentValues)
      }
      this.props.changeFilterSettingsAfterApply({ [this.props.getDimensionAndUniqueAnswers[0].campaignId]: {} })
    } else {
      let obj
      if (!getSegment.selectedSegmentValues.includes('ALL') && getSegment.segmentName !== 'ALL') {
        obj = {
          [selectedFilter.campaignId]: {
            [selectedFilter.questionid]: [...getSegment.selectedSegmentValues],
          },
        }
        this.props.changeFilterSettingsAfterApply(obj)
      }
      this.props.handleNPSScoreFilter(['PROMOTER', 'PASSIVE', 'DETRACTOR', '--ALL--'])
    }
    this.setState({ isApplyClicked: false })
    this.props.handleSegmentFilter(getSegment.segmentName, getSegment.selectedSegmentValues, this.props.values.selectedAllValues?.filter((val) => val !== 'ALL'), this.props.values.segmentLevels)
    this.setState({ isSegmentClicked: false })
  }

  updateSelectedQuestionFilters = (value: string[], key: string[], campaignIds: string[], options: { label: string, value: string }[]) => {
    const allOptions = options as { label: string, value: string }[]
    const obj = cloneDeep(this.state.selectedQuestionFilters)
    this.setState({ isApplyClicked: true })

    campaignIds.forEach((c) => {
      if (!obj[c]) obj[c] = {}
    })

    const hadAllSelected = !obj[campaignIds[0]]?.[key[0]] || obj[campaignIds[0]]?.[key[0]]?.findIndex((v) => v === allSelectionValue) !== -1
    const nowHasAllSelectedEarlier = value.findIndex((v) => v === allSelectionValue) !== -1

    if (hadAllSelected && nowHasAllSelectedEarlier) {
      campaignIds.forEach((c, itr: number) => {
        obj[c] = { ...obj[c], [key[itr]]: allOptions.filter((v) => v.value !== value[1]).map((v) => v.value) }
      })
    } else if (hadAllSelected && !nowHasAllSelectedEarlier) {
      campaignIds.forEach((c, itr: number) => {
        obj[c][key[itr]] = []
      })
    } else if (nowHasAllSelectedEarlier) {
      campaignIds.forEach((c, itr: number) => {
        delete obj[c][key[itr]]
      })
    } else {
      campaignIds.forEach((c, itr: number) => {
        obj[c][key[itr]] = value
        if (obj[c][key[itr]].length === options.length) {
          delete obj[c][key[itr]]
        }
      })
    }
    this.setState(() => ({ selectedQuestionFilters: obj }))
  }

  updateSelectedNPSFilters = (child) => {
    this.setState({ isApplyClicked: true })
    const val = child.props.value
    let arr = [...this.state.selectedNPSFilters]
    if (val === allSelectionValue) {
      if (arr.length < 4) {
        arr = ['PROMOTER', 'PASSIVE', 'DETRACTOR', allSelectionValue]
      } else {
        arr = []
      }
    } else if (arr.indexOf(val) > -1) {
      arr = arr.filter((item) => item !== val && item !== allSelectionValue)
    } else {
      arr = [...arr, val]
      if (arr.length === 3) {
        arr.push(allSelectionValue)
      }
    }
    this.setState(() => ({ selectedNPSFilters: arr }))
  }

  updateSelectedResponsTypeFilters = (child) => {
    this.setState({ isApplyClicked: true })
    const val = child.props.value
    let arr = [...this.state.selectedResponseFilters]
    if (val === allSelectionValue) {
      if (arr.length < 3) {
        arr = ['SUBMIT', 'PARTIAL', allSelectionValue]
      } else {
        arr = []
      }
    } else if (arr.indexOf(val) > -1) {
      arr = arr.filter((item) => item !== val && item !== allSelectionValue)
    } else {
      arr = [...arr, val]
      if (arr.length === 2) {
        arr.push(allSelectionValue)
      }
    }
    this.setState(() => ({ selectedResponseFilters: arr }))
  }

  clearStateFilter = () => {
    this.setState({ selectedNPSFilters: ['PROMOTER', 'PASSIVE', 'DETRACTOR', allSelectionValue] })
    this.setState({ selectedResponseFilters: ['SUBMIT', 'PARTIAL', allSelectionValue] })
    this.setState({ selectedQuestionFilters: {} })
  }

  getDate = (start, end) => {
    const startDetails = start.split('-')
    const endDetails = end.split('-')
    const startDate: Date | null = new Date(startDetails[0] as unknown as number, startDetails[1] - 1 as unknown as number, startDetails[2] as unknown as number)
    const endDate: Date | null = new Date(endDetails[0] as unknown as number, endDetails[1] - 1 as unknown as number, endDetails[2] as unknown as number)
    return [startDate, endDate]
  }

  getSegmentName = (segmentName: string, segmentValue: string[]) => {
    let segmentLabel: string

    if (!this.props.values.showValue) return ''

    if (segmentValue.includes('ALL')) {
      segmentLabel = 'ALL'
    } else if (segmentValue.length === 1) {
      // eslint-disable-next-line max-len
      segmentLabel = this.props.getDimensionAndUniqueAnswers.find((val) => val.attributeName === segmentName)?.options.find((opt) => opt.value === segmentValue[0])?.label
    } else {
      return `(${segmentValue.length})`
    }
    return segmentLabel ? `(${formateMessage(segmentLabel, segmentLabel)})` : ''
  }

  getSegmentValues = async (indexVal: number, segmentName: string) => {
    try {
      const segmentValuesArray: string[] = []
      const { data } = await this.props.client.query({
        query: GET_SEGMENT_VALUE,
        variables: {
          segmentName: this.props.values.segmentLevels[indexVal].segmentName,
          segmentValue: this.props.values.segmentLevels[indexVal].selectedSegmentValues.length > 0 ? this.props.values.segmentLevels[indexVal].selectedSegmentValues : this.props.values.segmentLevels[indexVal].segmentValue,
          campaignId: this.props.match.params.campaignId,
          getSelectionForSegment: segmentName,
        },
      })
      if (data.segmentValues.length > 0) {
        const keyName = Object.keys(data.segmentValues[0])[0]
        data.segmentValues.forEach((item) => {
          segmentValuesArray.push(item[keyName])
        })
      }
      if (!segmentValuesArray.includes('ALL')) {
        if (segmentValuesArray.length > 1) {
          return ['ALL'].concat(segmentValuesArray.sort())
        }
        return segmentValuesArray
      }
      const index = segmentValuesArray.indexOf('ALL')
      if (index > -1) {
        segmentValuesArray.splice(index, 1)
      }
      return ['ALL'].concat(segmentValuesArray.sort())
    } catch (e) {
      console.error('failed to fetch the query')
      return []
    }
  }

  addSelectForNewSegment = async (segLevelLength: number) => {
    const nextSegmentIdx = (ele, i) => {
      const currSegmentIdx = this.props.getDimensionAndUniqueAnswers.findIndex((data) => data.attributeName === this.props.values.segmentLevels[segLevelLength - 1].segmentName)
      return i > currSegmentIdx && this.usableSegment.includes(ele.attributeName)
    }
    const idx = this.props.getDimensionAndUniqueAnswers.findIndex(nextSegmentIdx)
    const segmentName = this.props.getDimensionAndUniqueAnswers[idx].attributeName
    const segmentValuesArray = await this.getSegmentValues(segLevelLength - 1, segmentName)
    this.props.setFieldValue('segmentLevels', [
      ...this.props.values.segmentLevels.slice(0, segLevelLength),
      {
        segmentName,
        segmentValue: segmentValuesArray,
        selectedSegmentValues: segmentValuesArray.includes('ALL') ? ['ALL'] : [],
      },
    ])
    this.props.setFieldValue('selectedAllValues', segmentValuesArray.filter((val) => val !== 'ALL'))
    this.checkIfLastSegment(segmentName)
  }

  removeSelectedSegmentFilter = async (segmentLevelLength) => {
    this.props.setErrors({})
    this.props.values.segmentLevels = [...this.props.values.segmentLevels].splice(0, segmentLevelLength - 1)
    if (this.props.values.segmentLevels.length === 1) {
      this.props.setFieldValue('selectedAllValues', getAllValues(this.props.getDimensionAndUniqueAnswers[0]?.options))
    } else {
      this.props.setFieldValue('selectedAllValues', this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].segmentValue.filter((val) => val !== 'ALL'))
    }
  }

  checkIfLastSegment = (segmentName) => {
    if (this.props.getDimensionAndUniqueAnswers[this.props.getDimensionAndUniqueAnswers.length - 1].attributeName === segmentName) {
      this.setState({ isLastSegment: true })
    } else {
      this.setState({ isLastSegment: false })
    }
    const nextSegmentIdx = (ele, i) => {
      const currSegmentIdx = this.props.getDimensionAndUniqueAnswers.findIndex((data) => data.attributeName === segmentName)
      return i > currSegmentIdx && this.usableSegment.includes(ele.attributeName)
    }
    const idx = this.props.getDimensionAndUniqueAnswers.findIndex(nextSegmentIdx)
    if (idx < 0) this.setState({ isLastSegment: true })
  }

  render() {
    const dimensionFromLocalStorage = localStorage.getItem(`${this.props.userData.firstName}-${this.props.match.params.campaignId}-getDimensionAndUniqueAnswers`) !== null ? JSON.parse(localStorage.getItem(`${this.props.userData.firstName}-${this.props.match.params.campaignId}-getDimensionAndUniqueAnswers`) as string).data : this.props.getDimensionAndUniqueAnswers
    const uniqueDimensions = _.groupBy(dimensionFromLocalStorage, (v) => v.attributeName)
    const uniqueValuesForDimension: { [key: string]: { label: string, value: string, shortCode: string }[] } = {}
    Object.keys(uniqueDimensions).forEach((v) => {
      const allValues = uniqueDimensions[v].map((u) => u.options).flat()
      uniqueValuesForDimension[v] = _.uniqBy(allValues, (o: { label: string, value: string }) => o.value)
    })

    const uniqueValuesForMapping: { [key: string]: { label: string, value: string, shortCode: string }[] } = {}
    Object.keys(uniqueDimensions).forEach((v) => {
      const allValues = uniqueDimensions[v].map((u) => u.mapping).flat()
      uniqueValuesForMapping[v] = _.uniqBy(allValues, (o: { label: string, value: string }) => o.value)
    })

    const getDimensionAndUniqueAnswers = Object.keys(uniqueDimensions).map((v) => ({
      campaignIds: uniqueDimensions[v].map((d) => d.campaignId),
      attributeName: v,
      shortCode: uniqueDimensions[v][0].shortCode,
      questionStatement: uniqueDimensions[v][0].questionStatement,
      options: uniqueValuesForDimension[v],
      mapping: uniqueValuesForMapping[v][0],
      questionIds: uniqueDimensions[v].map((d) => d.questionid),
    }))

    let copyOfGetDimensions = cloneDeep(this.props.getDimensionAndUniqueAnswers)

    const cascade = (value, campaignIds, keys) => {
      const reqObject = getDimensionAndUniqueAnswers.find((x) => value === x.attributeName)?.mapping[value]?.find((y) => keys.includes(y.value))
      const reqObjectArray = getDimensionAndUniqueAnswers.find((x) => value === x.attributeName)?.mapping[value]?.filter((y) => keys.includes(y.value))
      const objIndex = copyOfGetDimensions.findIndex(((obj) => obj.attributeName === value))

      if (copyOfGetDimensions[objIndex]?.options) {
        const temp = cloneDeep(copyOfGetDimensions)
        const tempobj = { ...temp[objIndex] }
        tempobj.options = reqObjectArray
        temp[objIndex] = tempobj
        copyOfGetDimensions = cloneDeep(temp)
      }
      const dataToDispatch = {
        getDimensionAndUniqueAnswers: copyOfGetDimensions,
      }

      if (copyOfGetDimensions[objIndex]?.options?.length > 0) {
        this.props.handleOptionsChange(dataToDispatch)
      }
      const nextFilter = reqObject?.nextFilterLabel

      if (reqObjectArray?.length > 0) {
        const nextKeys: string[] = []
        reqObjectArray.forEach((obj) => {
          obj?.options.forEach((option) => { if (option) nextKeys.push(option.value as string) })
        })
        cascade(nextFilter, campaignIds, nextKeys)
      }
    }
    // eslint-disable-next-line max-len
    const NPS_SCORE_FILTER_OPTIONS: Array<{ label: string, value: string }> = []
    if (this.props.metricConfig) {
      const npsMetricConfig = this.props.metricConfig.find((x) => x.type === 'NPS') as IMetricConfig
      if (npsMetricConfig) {
        NPS_SCORE_FILTER_OPTIONS.push({ label: npsMetricConfig.promoterLabel, value: 'PROMOTER' })
        if (npsMetricConfig.detractorUpperRange !== npsMetricConfig.promoterLowerRange) NPS_SCORE_FILTER_OPTIONS.push({ label: npsMetricConfig.passiveLabel, value: 'PASSIVE' })
        NPS_SCORE_FILTER_OPTIONS.push({ label: npsMetricConfig.detractorLabel, value: 'DETRACTOR' })
      }
    }
    // if ((this.props.selectedTab === '/satisfaction-score-pre' || this.props.selectedTab === '/engagement-summary') && this.props.values.showValue) { // || this.props.selectedTab === '/kpi-satisfaction-pre'
    //   this.props.setFieldValue('showValue', false)
    // } else if (!(this.props.selectedTab === '/satisfaction-score-pre' || this.props.selectedTab === '/engagement-summary') && !this.props.values.showValue) { // || this.props.selectedTab === '/kpi-satisfaction-pre'
    //   this.props.setFieldValue('showValue', true)
    // }

    const resetSegmentFilter = () => {
      const newObj = {
        [getDimensionAndUniqueAnswers[0].campaignIds[0]]: {
          [getDimensionAndUniqueAnswers[0].questionIds[0]]: [getDimensionAndUniqueAnswers[0].options[0].value],
        },
      }
      this.props.changeFilterSettingsAfterApply(newObj)
      this.props.handleNPSScoreFilter(['PROMOTER', 'PASSIVE', 'DETRACTOR', '--ALL--'])
      // eslint-disable-next-line max-len
      let segObj
      if (!getDimensionAndUniqueAnswers[0].options.find((val) => val.label === 'ALL')) {
        segObj = {
          segmentName: getDimensionAndUniqueAnswers[0].attributeName,
          segmentValue: ['ALL'],
          selectedSegmentValues: ['ALL'],
        }
        this.props.handleSegmentFilter(getDimensionAndUniqueAnswers[0].attributeName, getDimensionAndUniqueAnswers[0].options.map((val) => val.value), getAllValues(getDimensionAndUniqueAnswers[0].options), [segObj])
      } else {
        segObj = {
          segmentName: getDimensionAndUniqueAnswers[0].attributeName,
          segmentValue: [getDimensionAndUniqueAnswers[0].options[0].value],
          selectedSegmentValues: [getDimensionAndUniqueAnswers[0].options[0].value],
        }
        this.props.handleSegmentFilter(getDimensionAndUniqueAnswers[0].attributeName, [getDimensionAndUniqueAnswers[0].options[0].value], getAllValues(getDimensionAndUniqueAnswers[0].options), [segObj])
      }
      this.props.setFieldValue('segmentLevels', [segObj])
      this.setState({ isSegmentClicked: false, isLastSegment: false })
    }

    if (this.props.selectedTab !== '/kpi-satisfaction-pre' && this.props.segmentFilter.segmentName === 'ALL') {
      resetSegmentFilter()
    }

    const circleOptions = () => {
      const options: string[] = []
      if (this.props.selectedTab === '/kpi-satisfaction-pre' && !this.props.isTabAuthorised) {
        options.push('ALL')
      }
      getDimensionAndUniqueAnswers.map((elem) => {
        options.push(elem.attributeName)
      })
      { /* Enable NPS filter later */ }
      { /* {this.props.hasNetPromoterScoreQuestion && (
      <MenuItem value="NPS">NPS</MenuItem>
      )} */ }
      return options
    }

    const circleValues = () => {
      const values: string[] = []
      if (this.props.values.segmentLevels[0].segmentName !== 'NPS') {
        getDimensionAndUniqueAnswers.find((val) => val.attributeName === this.props.values.segmentLevels[0].segmentName)?.options.map((item) => {
          values.push(item.label)
        })
      } else {
        NPS_SCORE_FILTER_OPTIONS.map((opt) => {
          values.push(opt.label)
        })
      }
      if (!getDimensionAndUniqueAnswers.find((val) => val.attributeName === this.props.values.segmentLevels[0].segmentName)?.options.find((i) => i.label === 'ALL')) {
        return ['ALL'].concat(values.sort())
      }
      const index = values.indexOf('ALL')
      if (index > -1) {
        values.splice(index, 1)
      }
      return ['ALL'].concat(values.sort())
    }
    return (
      <>
        <div className={this.props.exclude ? 'flex flex-wrap gap-2 items-center mt-2 mb-4' : 'flex flex-wrap gap-2 items-center mt-2 mb-4 mx-4'}>
          {(this.props.getCampaignEnabledStatusforPreCalculation?.getCampaignsEnabledForPrecalculation?.includes(this.props.match.params.campaignId))
            ? (
              <Button
                variant="outlined"
                className="mr-2"
                color="inherit"
                sx={{ marginRight: '0.50rem' }}
                startIcon={<FilterListIcon />}
                onClick={(e) => {
                  this.setState({ segmentAnchorEl: e.currentTarget })
                  this.setState((prevState) => ({ isSegmentClicked: !prevState.isSegmentClicked }))
                }}
              >
                <strong>{`${formateMessage(this.props.segmentFilter.segmentName ?? '', this.props.segmentFilter.segmentName ?? '')} ${this.props.segmentFilter.segmentName !== 'NPS' ? this.getSegmentName(this.props.segmentFilter.segmentName, this.props.segmentFilter.segmentValue) : formateMessage(this.props.segmentFilter.segmentValue[0], this.props.segmentFilter.segmentValue[0])}`}</strong>
              </Button>
            ) : (
              <Button color="primary" variant="contained" sx={{ marginRight: '0.50rem' }} onClick={() => this.props.changeFilterVisibility(true)} title="Filter">
                <Badge
                  // eslint-disable-next-line max-len
                  badgeContent={(this.props.npsScoreFilter.length >= 3 ? 0 : 1) + Object.keys(Object.values(this.props.questionFilter).reduce((acc, cur) => ({ ...acc, ...cur }), {})).length}
                  color="secondary"
                >
                  <FilterListIcon />
                </Badge>
              </Button>
            )}
          {this.state.isSegmentClicked && (
            <div id="segment-filter">
              <Popper
                open={this.state.isSegmentClicked}
                anchorEl={this.state.segmentAnchorEl}
                placement="bottom-start"
                transition
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: 4,
                  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.5)',
                  zIndex: 999,
                }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className="p-4">
                      <div className="flex flex-row justify-between mb-4">
                        <div className="mr-2 w-48">
                          <FormControl
                            fullWidth
                          >
                            <Autocomplete
                              size="small"
                              value={{
                                value: this.props.values.segmentLevels[0].segmentName,
                                label: formateMessage(this.props.values.segmentLevels[0].segmentName, this.props.values.segmentLevels[0].segmentName),
                              }}
                              options={circleOptions().map((vals) => ({ value: vals, label: formateMessage(vals, vals) })) || []}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => option.value === this.props.values.segmentLevels[0].segmentName}
                              // getOptionValue={(option) => option.value}
                              renderInput={(params) => <TextField {...params} label={formateMessage('Segment', 'Segment')} variant="outlined" error={_.get(this.props.touched.segmentLevels, '[0].segmentName') && _.get(this.props.errors.segmentLevels, '[0].segmentName') !== undefined} />}
                              onChange={(event: any, newValue: { label: string, value: string }) => {
                                this.props.setFieldValue('selectedAllValues',
                                  getAllValues(getDimensionAndUniqueAnswers.find((v) => v.attributeName === newValue?.value || '')?.options || []))
                                this.setState({ isApplyClicked: true })
                                this.props.setFieldValue('segmentLevels', [{ segmentName: newValue?.value || '', segmentValue: [getAllValues(getDimensionAndUniqueAnswers.find((v) => v.attributeName === newValue?.value || '')?.options || [])[0]], selectedSegmentValues: [getAllValues(getDimensionAndUniqueAnswers.find((v) => v.attributeName === newValue?.value || '')?.options || [])[0]] }])
                                this.checkIfLastSegment(newValue?.value || '')
                              }}
                            />
                            {_.get(this.props.errors.segmentLevels, '[0].segmentName') && (<FormHelperText className="text-red-600">{_.get(this.props.errors.segmentLevels, '[0].segmentName')}</FormHelperText>)}
                          </FormControl>
                          </div>
                        {this.props.values.showValue && (
                          <div className="w-56">
                            <FormControl
                              fullWidth
                            >
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                size="small"
                                value={this.props.values.segmentLevels[0].selectedSegmentValues.map(val => ({
                                  value: val, // Actual value used in backend
                                  label: formateMessage(val, val), // Translated label
                                }))}
                                options={circleValues()?.map((vals) => ({ value: vals, label: formateMessage(vals, vals) })) || []}
                                getOptionLabel={(option) => option.label}  // Display 'label'
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionDisabled={(option) => this.props.values.segmentLevels[0].segmentName !== 'NPS' && this.props.values.segmentLevels[0].segmentValue.includes('ALL') && option.value !== 'ALL'}
                                renderTags={(value, getTagProps) => (
                                  <p className="ml-2">{displayFilterValue(value.map((vals) => vals.label))}</p>
                                )}
                                renderInput={(params) => <TextField {...params} label={formateMessage('Segment Value', 'Segment Value')} variant="outlined" error={_.get(this.props.touched.segmentLevels, '[0].segmentValue') && _.get(this.props.errors.segmentLevels, '[0].segmentValue') !== undefined} />}
                                onChange={(event: any, newValue) => {
                                  if (newValue.find((vals)=> vals.value === 'ALL')?.value) {
                                    this.props.setFieldValue('segmentLevels', [{ segmentName: this.props.values.segmentLevels[0].segmentName, segmentValue: ['ALL'], selectedSegmentValues: ['ALL'] }])
                                  } else {
                                    this.props.setFieldValue('segmentLevels', [{ segmentName: this.props.values.segmentLevels[0].segmentName, segmentValue: newValue.map((val) => val.value) || [], selectedSegmentValues: newValue.map((val) => val.value) || [] }])
                                  }
                                  this.setState({ isApplyClicked: true })
                                }}
                              />
                              {_.get(this.props.errors.segmentLevels, '[0].segmentValue') !== undefined && (<FormHelperText className="text-red-600">{_.get(this.props.errors.segmentLevels, '[0].segmentValue')}</FormHelperText>)}
                            </FormControl>
                          </div>
                        )}
                      </div>
                      {this.props.values.segmentLevels.map((val, i) => {
                        if (this.props.values.showValue) {
                          if (i > 0) {
                            let arrIndex
                            const data: { [key: string]: any }[] = []
                            this.props.getDimensionAndUniqueAnswers.forEach((val, idx) => {
                              if (val.attributeName === this.props.values.segmentLevels[i - 1].segmentName) arrIndex = idx
                            })

                            for (let i = arrIndex + 1; i < this.props.getDimensionAndUniqueAnswers.length; i += 1) {
                              if (this.usableSegment.includes(this.props.getDimensionAndUniqueAnswers[i].attributeName)) {
                                data.push(this.props.getDimensionAndUniqueAnswers[i])
                              }
                            }
                            return (
                              <div key={val.segmentName} className="flex flex-row justify-between mb-4">
                                <div className="mr-2 w-48">
                                  <FormControl
                                    fullWidth
                                  >
                                  <Autocomplete
                                    size="small"
                                    value={{
                                      value: val.segmentName,
                                      label: formateMessage(val.segmentName, val.segmentName),
                                    }}
                                    options={
                                      data.map((elem) => ({
                                        value: elem.attributeName,
                                        label: formateMessage(elem.attributeName, elem.attributeName),
                                      })) || []
                                    }
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={formateMessage('Segment', 'Segment')}
                                        variant="outlined"
                                        error={
                                          _.get(this.props.touched.segmentLevels, `[${i}].segmentName`) &&
                                          _.get(this.props.errors.segmentLevels, `[${i}].segmentName`) !== undefined
                                        }
                                      />
                                    )}
                                    onChange={async (event: any, newValue: { label: string; value: string }) => {
                                      this.setState({ isApplyClicked: true });
                                      const segmentValueArray = await this.getSegmentValues(i - 1, newValue.value || '');
                                      this.props.setFieldValue('segmentLevels', [
                                        ...this.props.values.segmentLevels.slice(0, i),
                                        {
                                          segmentName: newValue.value || '',
                                          segmentValue: segmentValueArray,
                                          selectedSegmentValues: segmentValueArray.includes('ALL') ? ['ALL'] : [],
                                        },
                                      ]);
                                      this.props.setFieldValue(
                                        'selectedAllValues',
                                        segmentValueArray.filter((val) => val !== 'ALL')
                                      );
                                      this.checkIfLastSegment(newValue.value || '');
                                    }}
                                  />
                                  {_.get(this.props.errors.segmentLevels, `[${i}].segmentName`) !== undefined && (
                                    <FormHelperText className="text-red-600">
                                      {_.get(this.props.errors.segmentLevels, `[${i}].segmentName`)}
                                    </FormHelperText>
                                  )}
                                  </FormControl>
                                </div>
                                <div className="w-56">
                                  <FormControl
                                    fullWidth
                                  >
                                    <Autocomplete
                                      multiple
                                      disableCloseOnSelect
                                      size="small"
                                      value={val.selectedSegmentValues.map((crrOption) =>({ value: crrOption, label: formateMessage(crrOption, crrOption) }))}
                                      options={val.segmentValue.map((opt) => ({ label: formateMessage(opt, opt), value: opt}))|| []}
                                      getOptionLabel={(option) => option.label}  // Display 'label'
                                      // getOptionValue={(option) => option.value}  // Use 'value' for selection
                                      getOptionDisabled={(option) => this.props.values.segmentLevels[i].selectedSegmentValues.includes('ALL') && option.value !== 'ALL'}
                                      renderTags={(value, getTagProps) => (
                                        <p className="ml-2">{displayFilterValue(value.map((crrOption) => crrOption.value))}</p>
                                      )}
                                      renderInput={(params) => <TextField {...params} label="Segment Value" variant="outlined" error={_.get(this.props.touched.segmentLevels, `[${i}].selectedSegmentValues`) && _.get(this.props.errors.segmentLevels, `[${i}].selectedSegmentValues`) !== undefined} />}
                                      onChange={(event: any, newValue) => {
                                       if (newValue.includes(formateMessage('ALL', 'ALL'))) {
                                          this.props.setFieldValue('segmentLevels', [
                                            ...this.props.values.segmentLevels.slice(0, i),
                                            {
                                              segmentName: val.segmentName,
                                              segmentValue: val.segmentValue,
                                              selectedSegmentValues: ['ALL'],
                                            },
                                          ])
                                        } else {
                                          this.props.setFieldValue('segmentLevels', [
                                            ...this.props.values.segmentLevels.slice(0, i),
                                            {
                                              segmentName: val.segmentName,
                                              segmentValue: val.segmentValue,
                                              selectedSegmentValues: newValue.map((val) => val.value) || [],
                                            },
                                          ])
                                        }
                                        // this.props.setFieldValue('selectedAllValues', val.segmentValue.filter((val) => val !== 'ALL'))
                                        this.setState({ isApplyClicked: true })
                                      }}
                                    />
                                    {_.get(this.props.errors.segmentLevels, `[${i}].selectedSegmentValues`) !== undefined && (<FormHelperText className="text-red-600">{_.get(this.props.errors.segmentLevels, `[${i}].selectedSegmentValues`)}</FormHelperText>)}
                                  </FormControl>
                                </div>
                              </div>
                            )
                          }
                        }
                        return <></>
                      })}
                      <div className="flex">
                        {
                          (
                            (this.props.values.segmentLevels.length > 1)
                            // && !this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].segmentValue.includes('ALL')
                            && (this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].segmentValue.length > 0)
                            // && !(this.props.selectedTab === '/satisfaction-score-pre' || this.props.selectedTab === '/engagement-summary')
                          ) && (
                            <div>
                              <IconButton onClick={async () => {
                                await this.removeSelectedSegmentFilter(this.props.values.segmentLevels.length)
                                this.setState({ isApplyClicked: true, isLastSegment: false })
                              }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </div>
                          )
                        }
                        {
                          (
                            this.props.values.segmentLevels.length < 3 && this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].selectedSegmentValues.length > 0
                            && !this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].selectedSegmentValues.includes('ALL')
                            && this.props.values.segmentLevels[this.props.values.segmentLevels.length - 1].segmentValue.length > 0
                            && this.usableSegment.includes(this.props.values.segmentLevels[0].segmentName)
                            // && !(this.props.selectedTab === '/satisfaction-score-pre' || this.props.selectedTab === '/engagement-summary')
                          ) && (
                            <div>
                              <IconButton
                                disabled={this.state.isLastSegment}
                                onClick={async () => {
                                  this.props.setFieldValue('isNewSegmentEnabled', true)
                                  await this.addSelectForNewSegment(this.props.values.segmentLevels.length)
                                  this.setState({ isApplyClicked: true })
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          )
                        }
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <Button
                            title="Clear all filters"
                            onClick={() => resetSegmentFilter()}
                          >
                            {formateMessage('Reset all', 'Reset all')}
                          </Button>
                        </div>
                        <div>
                          <Button
                            className="mr-2"
                            title={formateMessage('Apply', 'Apply')}
                            variant={this.state.isApplyClicked ? 'contained' : 'outlined'}
                            color="primary"
                            disabled={!this.state.isApplyClicked || Object.keys(this.props.errors).length > 0}
                            style={{
                              minWidth: 'max-content',
                            }}
                            onClick={this.applySegment}
                          >
                            {formateMessage('Apply', 'Apply')}
                          </Button>
                          <Button
                            className="mr-2"
                            title={formateMessage('close', 'close')}
                            onClick={() => {
                              this.props.setFieldValue('segmentLevels', this.props.getSegmentLevels)
                              this.setState({ isSegmentClicked: false, isLastSegment: false })
                            }}
                          >
                            {formateMessage('CANCEL', 'CANCEL')}
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          )}
          <DateRangeSelector
            selectedDateFrom={this.props.values.selectedDateFrom}
            selectedDateTo={this.props.values.selectedDateTo}
            handleDateChange={async (selectedStartDate, selectedEndDate) => {
              let [startDate, endDate] = this.getDate(selectedStartDate, selectedEndDate)
              startDate = (startDate ? startOfDay(startDate) : null) as Date
              endDate = (endDate ? endOfDay(endDate) : null) as Date
              if (startDate && startDate.getTime() && endDate && endDate.getTime()) {
                if (this.props.huddleDateFilter) await this.props.handleDateFilterHuddle([startDate, endDate])
                else this.handleTimerangeSelection([startDate, endDate])
              }
            }}
            huddleDateFilter={this.props.huddleDateFilter}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            campaignId={this.props.match.params.campaignId}
          />
          <ThemeProvider theme={theme}>
            <Drawer
              anchor="left"
              variant="temporary"
              open={this.props.isFilterDrawerVisible}
              onClose={() => {
                if (this.state.isApplyClicked) {
                  this.setState({ isDialogBoxOpen: true })
                } else {
                  this.props.changeFilterVisibility(false)
                }
              }}
            >
              <div className={cx(this.props.className, 'p-4')}>
                <div className="flex justify-between items-center">
                  <Button
                    title="Clear all filters"
                    onClick={() => {
                      this.props.clearFilterSettings()
                      this.clearStateFilter()
                    }}
                  >
                    {formateMessage('Clear all', 'Clear all')}
                  </Button>
                  <Button
                    title="Apply"
                    variant={this.state.isApplyClicked ? 'contained' : 'outlined'}
                    color="secondary"
                    disabled={!this.state.isApplyClicked}
                    style={{
                      minWidth: 'max-content',
                    }}
                    onClick={this.applyFilters}
                  >
                    {formateMessage('Apply', 'Apply')}
                  </Button>
                  <IconButton
                    title="Hide"
                    onClick={() => {
                      if (this.state.isApplyClicked) {
                        this.setState({ isDialogBoxOpen: true })
                      } else {
                        this.props.changeFilterVisibility(false)
                      }
                    }}
                  >
                    {this.props.theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
                <Divider />
                {getDimensionAndUniqueAnswers.map((elem) => (
                  <div className="mt-6" key={elem.questionStatement}>
                    <FormControl
                      fullWidth
                      key={elem.questionStatement}
                      variant="standard"
                    >
                      {elem.shortCode.length > 0 ? elem.shortCode : elem.questionStatement}
                      <Select
                        labelId={`filter-${elem.questionStatement}`}
                        value={this.state.selectedQuestionFilters[elem.campaignIds[0]]?.[elem.questionIds[0]] || [allSelectionValue]}
                        onChange={(e) => {
                          this.updateSelectedQuestionFilters(e.target.value as string[], elem.questionIds, elem.campaignIds, elem.mapping[elem.attributeName])
                          this.setState({ selectedOption: e.target.value as string[] })
                        }}
                        onClose={() => cascade(elem.attributeName, elem.campaignIds, this.state.selectedOption)}
                        multiple
                        fullWidth
                        className="mr-4"
                        renderValue={(selected: string[]) => {
                          if (selected[0] === allSelectionValue) {
                            return 'All'
                          }
                          return `${selected.length} ${formateMessage('of', 'of')} ${elem.options.length} ${formateMessage('selected', 'selected')}`
                        }}
                      >
                        <MenuItem key={allSelectionValue} value={allSelectionValue}>
                          <>
                            <Checkbox checked={this.state.selectedQuestionFilters[elem.campaignIds[0]]?.[elem.questionIds[0]] === undefined} />
                            <ListItemText primary={formateMessage('All', 'All')} />
                          </>
                        </MenuItem>
                        {elem.options.sort((a, b) => (a.shortCode ? a.shortCode.toString().toLowerCase() : a.label.toString().toLowerCase()).localeCompare(b.shortCode ? b.shortCode.toString().toLowerCase() : b.label.toString().toLowerCase())).map((item) => (
                          (
                            <MenuItem key={item.value} value={formateMessage(item.value, item.value)}>
                              <>
                                <Checkbox checked={this.state.selectedQuestionFilters[elem.campaignIds[0]]?.[elem.questionIds[0]]?.findIndex((v) => v === item.value) !== -1} />
                                <ListItemText primary={item.shortCode?.length ? formateMessage(item.shortCode, item.shortCode) : formateMessage(item.label, item.label)} />
                              </>
                            </MenuItem>
                          )
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ))}
                {
                  this.props.hasNetPromoterScoreQuestion && (
                    <FormControl variant="standard" sx={{ marginTop: '1.5rem' }} fullWidth>
                      {formateMessage('NPS', 'NPS')}
                      <Select
                        multiple
                        fullWidth
                        value={this.state.selectedNPSFilters}
                        onChange={(e, child) => this.updateSelectedNPSFilters(child)}
                        renderValue={(selected: string[]) => {
                          if (selected.includes(allSelectionValue)) {
                            return 'All'
                          }
                          return `${selected.length} ${formateMessage('of 3 selected', 'of 3 selected')}`
                        }}
                      >
                        <MenuItem key={allSelectionValue} value={allSelectionValue}>
                          <Checkbox checked={this.state.selectedNPSFilters.length >= NPS_SCORE_FILTER_OPTIONS.length} />
                          <ListItemText primary={formateMessage('All', 'All')} />
                        </MenuItem>
                        {
                          NPS_SCORE_FILTER_OPTIONS.sort((a, b) => a.label.toString().toLowerCase().localeCompare(b.label.toString().toLowerCase())).map((opt, i) => (
                            <MenuItem key={i.toString()} value={opt.value}>
                              <Checkbox checked={this.state.selectedNPSFilters.findIndex((x) => x === opt.value) !== -1} />
                              <ListItemText>{formateMessage(opt.label, opt.label)}</ListItemText>
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  )
                }
                <FormControl sx={{ marginTop: '1.5rem' }} variant="standard" fullWidth>
                  {formateMessage('Response Type', 'Response Type')}
                  <Select
                    multiple
                    fullWidth
                    value={this.state.selectedResponseFilters}
                    onChange={(e, child) => this.updateSelectedResponsTypeFilters(child)}
                    renderValue={(selected: string[]) => {
                      if (selected.includes(allSelectionValue)) {
                        return 'All'
                      }
                      return `${selected.length} ${formateMessage('of 3 selected', 'of 3 selected')}`
                    }}
                  >
                    <MenuItem key={allSelectionValue} value={allSelectionValue}>
                      <Checkbox checked={this.state.selectedResponseFilters.length >= 2} />
                      <ListItemText primary={formateMessage('All', 'All')} />
                    </MenuItem>
                    {
                      [{ label: 'Completed', value: 'SUBMIT' }, { label: 'Partial', value: 'PARTIAL' }].map((opt, i) => (
                        <MenuItem key={i.toString()} value={opt.value}>
                          <Checkbox checked={this.state.selectedResponseFilters.findIndex((x) => x === opt.value) !== -1} />
                          <ListItemText>{formateMessage(opt.label, opt.label)}</ListItemText>
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
            </Drawer>
          </ThemeProvider>
        </div>
        <Dialog
          open={this.state.isDialogBoxOpen}
          onClose={() => this.props.changeFilterVisibility(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formateMessage('Do you want to discard the filters?', 'Do you want to discard the filters?')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formateMessage('You have not clicked &quot;APPLY&quot; button. Are you sure you want to discard?', 'You have not clicked &quot;APPLY&quot; button. Are you sure you want to discard?').replace(/&quot;/g, '\u00A0')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.discardFilters} variant="outlined">
              {formateMessage('Discard', 'Discard')}
            </Button>
            <Button onClick={() => this.setState({ isDialogBoxOpen: false })} variant="outlined">
              {formateMessage('Stay', 'Stay')}
            </Button>
            <Button onClick={this.applyFilters} variant="contained" color="secondary">
              {formateMessage('Apply', 'Apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  timeRangeStartDate: state.globalFilters.timeRangeStartDate,
  timeRangeEndDate: state.globalFilters.timeRangeEndDate,
  dimension: state.globalFilters.dimension,
  questionFilter: state.globalFilters.appliedFilters.questionFilter,
  questionFilterAllOptions: state.globalFilters.questionFilterAllOptions,
  isFilterDrawerVisible: state.globalFilters.isFilterDrawerVisible,
  npsScoreFilter: state.globalFilters.appliedFilters.npsScoreFilter.values,
  responseType: state.globalFilters.appliedFilters.responseType.values,
  getDimensionAndUniqueAnswers: state.globalFilters.getDimensionAndUniqueAnswers,
  hasNetPromoterScoreQuestion: state.globalFilters.hasNetPromoterScoreQuestion,
  metricConfig: state.globalFilters.metricConfig,
  questionnaire: state.globalFilters.questionnaire,
  segmentFilter: state.globalFilters.segmentFilter,
  userData: state.auth.userData,
  getSegmentLevels: state.globalFilters.segmentLevels,
  languageSelected: state.currentCampaign?.currentSelectedLanguage || 'English',
  locale: state.currentCampaign?.locale || 'English',
})

const mapDispatchToProps = {
  handleTimerangeSelection: (startDate: Date, endDate: Date) => ({ type: 'CHANGE_TIMERANGE', payload: ({ startDate, endDate }) }),
  handleFilterChange: (param: any) => ({ type: 'LOAD_GLOBAL_FILTER', payload: param }),
  clearFilterSettings: () => ({ type: 'CLEAR_GLOBAL_FILTER' }),
  changeFilterSettingsAfterApply: (param: any) => ({ type: 'CHANGE_GLOBAL_FILTER_AFTER_APPLY', payload: param }),
  changeFilterVisibility: (visiblity: boolean) => ({ type: 'CHANGE_GLOBAL_FILTER_VISIBILTY', payload: visiblity }),
  handleNPSScoreFilter: (param: string) => ({ type: 'CHANGE_NPS_SCORE_FILTER', payload: param }),
  handleResponseTypeFilter: (param: string) => ({ type: 'CHANGE_RESPONSE_TYPE_FILTER', payload: param }),
  handleOptionsChange: (param: any) => ({ type: 'CHANGE_FILTER_OPTIONS', payload: param }),
  handleSegmentFilter: (segmentName: string, segmentValue: string[], allValues: string[], segmentLevels: { [key: string]: string | string[] }[]) => ({
    type: 'CHANGE_SEGMENT',
    payload: ({
      segmentName, segmentValue, allValues, segmentLevels,
    }),
  }),
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(gql`
    query {
      getCampaignsEnabledForPrecalculation
    }
  `, {
    name: 'getCampaignEnabledStatusforPreCalculation',
  }),
  withFormik<IOuterProps & IInnerProps, IValues>({
    enableReinitialize: true,
    mapPropsToValues: (props: IOuterProps & IInnerProps): IValues => {
      let [modStartDate, modEndDate] = formatDateForCalendarValue(props.timeRangeStartDate, props.timeRangeEndDate)
      if (props.huddleDateFilter) {
        if (props.startDate && props.endDate) {
          [modStartDate, modEndDate] = formatDateForCalendarValue(props.startDate, props.endDate)
        } else {
          modStartDate = props.startDate as string
          modEndDate = props.endDate as string
        }
      }
      return {
        selectedDateFrom: modStartDate,
        selectedDateTo: modEndDate,
        selectedAllValues: getAllValues(props.getDimensionAndUniqueAnswers[0]?.options),
        showValue: true,
        isNewSegmentEnabled: false,
        segmentLevels: props.getSegmentLevels,
      }
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {}
      const startDetails = values.selectedDateFrom.split('-')
      const endDetails = values.selectedDateTo.split('-')
      const startDate = new Date(Number(startDetails[0]), Number(startDetails[1]), Number(startDetails[2]))
      const endDate = new Date(Number(endDetails[0]), Number(endDetails[1]), Number(endDetails[2]))
      if (startDetails.length !== 3) {
        errors.selectedDateFrom = 'Invalid date'
      }
      if (endDetails.length !== 3) {
        errors.selectedDateTo = 'Invalid date'
      }
      if (endDate < startDate) {
        errors.selectedDateFrom = 'End date should be greater than start date'
      }
      if (values.segmentLevels) {
        values.segmentLevels.forEach((element, i) => {
          if (element.segmentName === '') {
            _.set(errors, `segmentLevels[${i}].segmentName`, 'Segment should not be empty')
          }
        })
      }
      if (values.segmentLevels) {
        values.segmentLevels.forEach((element, i) => {
          if (!element.segmentValue || element.segmentValue === undefined || element.segmentValue?.length === 0) {
            _.set(errors, `segmentLevels[${i}].segmentValue`, 'Segment value should not be empty')
          }
        })
      }
      if (values.segmentLevels) {
        values.segmentLevels.forEach((element, i) => {
          if (!element.selectedSegmentValues || element.selectedSegmentValues === undefined || element.selectedSegmentValues?.length === 0) {
            _.set(errors, `segmentLevels[${i}].selectedSegmentValues`, 'Segment value should not be empty')
          }
        })
      }
      return errors
    },
    handleSubmit: () => { },
  }),
)(Filter)
